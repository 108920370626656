var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.$screen.width <= 576
          ? _c("div", { staticClass: "my-expense-mobile" }, [
              _c("div", { staticClass: "title" }, [
                _c(
                  "h1",
                  {
                    staticClass: "main-page-title",
                    class: { "is-pwa": _vm.$isPwa() },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(10, "My timesheets")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "my-expense-actions",
                  class: { "pt-16": _vm.$isPwa() },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center wapper" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn-action text-center",
                          attrs: { to: "/timesheet" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "svgs/timesheet.svg",
                              alt: "Purshase order",
                            },
                          }),
                          _c("span", { staticClass: "px-2" }, [
                            _vm._v(_vm._s(_vm.FormMSG(52020, "Add Workday"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column w-100" },
                [
                  _c("list-timesheet-mobile", {
                    attrs: {
                      "time-sheets-approved": _vm.timeSheetsApproved,
                      "time-sheets-pending": _vm.timeSheetsPending,
                      "time-sheets-drafts": _vm.timeSheetsDrafts,
                      "time-sheets": _vm.Timesheets,
                      "can-see-approve": _vm.canSeeApprove,
                    },
                    on: { "submitted-all-ts": _vm.reloadData },
                  }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              [
                _vm.Timesheets
                  ? _c(
                      "b-row",
                      { staticClass: "animated fadeIn" },
                      [
                        _c(
                          "b-col",
                          {
                            class: `${
                              _vm.$screen.width <= 576 ? "my-project" : ""
                            }`,
                            attrs: { cols: "12", xl: "12" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "header-content" },
                              [
                                _c(
                                  "h1",
                                  {
                                    class: [
                                      `${
                                        !_vm.$screen.sm ? "main-page-title" : ""
                                      }`,
                                      { "is-pwa": _vm.$isPwa() },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(10, "My timesheets")
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn btn-success pull-right block-sm",
                                    attrs: { to: "/timesheet" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(11, "Add to timesheet")
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.isFilmSingle
                              ? _c(
                                  "b-card",
                                  { attrs: { "no-body": "" } },
                                  [
                                    _c(
                                      "b-card-body",
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "clearfix mb-4" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "fw-400",
                                                staticStyle: {
                                                  "font-size": "13px",
                                                  color:
                                                    "rgba(6, 38, 62, 0.65)",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t*\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        12,
                                                        "Click to a timesheet to see the details. Timesheets are divided by week from Monday to Sunday."
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        !_vm.$screen.md
                                          ? _c("CardListBuilder", {
                                              attrs: {
                                                items: _vm.Timesheets,
                                                fields: _vm.tsFieldsMobile,
                                                "hide-status": _vm.isFilmSingle,
                                              },
                                              on: {
                                                "update:items": function (
                                                  $event
                                                ) {
                                                  _vm.Timesheets = $event
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "actions",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.rowClicked(
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "icon-eye",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4217146928
                                              ),
                                            })
                                          : _vm._e(),
                                        _vm.$screen.md
                                          ? _c("b-table", {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                              attrs: {
                                                hover: _vm.hover,
                                                responsive: "sm",
                                                items: _vm.Timesheets,
                                                fields: _vm.tsFields,
                                                "current-page": _vm.currentPage,
                                                "per-page": _vm.perPage,
                                                "empty-text": _vm.FormMSG(
                                                  56,
                                                  "No timesheet found"
                                                ),
                                                bordered: "",
                                                small: "",
                                                "sticky-header": "700px",
                                                "head-variant": _vm.hv,
                                                "show-empty": "",
                                              },
                                              on: {
                                                "row-clicked": _vm.rowClicked,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "cell(validatedStatus)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wrap-status",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: `status ${data.item.statusClass}`,
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.7rem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      data.item
                                                                        .validatedStatus
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(encoder)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row align-items-center justify-content-center",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.encodedBy(
                                                                    data.item
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2827677654
                                              ),
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "b-tabs",
                                  {
                                    model: {
                                      value: _vm.currentExpenseTab,
                                      callback: function ($$v) {
                                        _vm.currentExpenseTab = $$v
                                      },
                                      expression: "currentExpenseTab",
                                    },
                                  },
                                  [
                                    _vm.canSeeApprove
                                      ? _c(
                                          "b-tab",
                                          {
                                            attrs: {
                                              title: _vm.FormMSG(
                                                10000,
                                                "Approve"
                                              ),
                                            },
                                          },
                                          [_c("ValidateTimesheets")],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          active: _vm.activeDraft,
                                          lazy: "",
                                          title: _vm.FormMSG(10001, "Drafts"),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          { attrs: { "no-body": "" } },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mb-4 ml-2 w-100 d-flex",
                                                    staticStyle: {
                                                      "justify-content":
                                                        "space-between",
                                                      "align-items": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm.timeSheetsDrafts
                                                      .length > 0
                                                      ? _c("div", [
                                                          _vm.itemIsNotSubmitted
                                                            .length <
                                                          _vm.timeSheetsDrafts
                                                            .length
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "pj-cb",
                                                                      attrs: {
                                                                        size: "lg",
                                                                        value: true,
                                                                        "unchecked-value": false,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.selectAllNotSubmitted,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.allIsSelected,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.allIsSelected =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "allIsSelected",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "label-txt",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "1px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                this.FormMSG(
                                                                                  10101011,
                                                                                  "Select all not submitted"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "pj-cb",
                                                                      attrs: {
                                                                        size: "lg",
                                                                        value: true,
                                                                        "unchecked-value": false,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.selectAllNotSubmitted,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.allIsSelected,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.allIsSelected =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "allIsSelected",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "label-txt",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "1px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                this.FormMSG(
                                                                                  1010103,
                                                                                  "Unselect all not submitted"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "btn-primary text-right",
                                                        attrs: {
                                                          disabled:
                                                            _vm
                                                              .itemIsNotSubmitted
                                                              .length == 0,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.sendForValidation,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                2266523,
                                                                "Send for validation"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                !_vm.$screen.md
                                                  ? _c("CardListBuilder", {
                                                      attrs: {
                                                        items: _vm.Timesheets,
                                                        fields:
                                                          _vm.tsFieldsMobile,
                                                        "hide-status":
                                                          _vm.isFilmSingle,
                                                      },
                                                      on: {
                                                        "update:items":
                                                          function ($event) {
                                                            _vm.Timesheets =
                                                              $event
                                                          },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "actions",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.rowClicked(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "icon-eye",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-3 mt-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pj-cb pb-1",
                                                                        attrs: {
                                                                          id: _vm.generateSecureId(
                                                                            `timesheets-efc-selected-${
                                                                              data
                                                                                .item
                                                                                .id +
                                                                              Math.random()
                                                                            }`
                                                                          ),
                                                                          name: _vm.generateSecureId(
                                                                            `timesheetsEfcSelected${data.item.id}`
                                                                          ),
                                                                          size: "lg",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleChangeTimesheetsEfcSelected(
                                                                                $event,
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .isSelected,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                data.item,
                                                                                "isSelected",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "data.item.isSelected",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1352369640
                                                      ),
                                                    })
                                                  : _vm._e(),
                                                _vm.$screen.md
                                                  ? _c("b-table", {
                                                      staticClass: "w-100",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                      attrs: {
                                                        hover: _vm.hover,
                                                        responsive: "sm",
                                                        items:
                                                          _vm.timeSheetsDrafts,
                                                        fields:
                                                          _vm.tsFieldsWithSelection,
                                                        "current-page":
                                                          _vm.currentPage,
                                                        "per-page": _vm.perPage,
                                                        "empty-text":
                                                          _vm.FormMSG(
                                                            56,
                                                            "No timesheet found"
                                                          ),
                                                        bordered: "",
                                                        small: "",
                                                        "sticky-header":
                                                          "700px",
                                                        "head-variant": _vm.hv,
                                                        "show-empty": "",
                                                      },
                                                      on: {
                                                        "row-clicked":
                                                          _vm.rowClicked,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "cell(select)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  [
                                                                    data.item
                                                                      .validated !==
                                                                      4 &&
                                                                    data.item
                                                                      .validated !==
                                                                      16
                                                                      ? _c(
                                                                          "b-form-checkbox",
                                                                          {
                                                                            staticClass:
                                                                              "pj-cb pb-1",
                                                                            attrs:
                                                                              {
                                                                                id: _vm.generateSecureId(
                                                                                  `timesheets-efc-selected-${
                                                                                    data
                                                                                      .item
                                                                                      .id +
                                                                                    Math.random()
                                                                                  }`
                                                                                ),
                                                                                name: _vm.generateSecureId(
                                                                                  `timesheetsEfcSelected${data.item.id}`
                                                                                ),
                                                                                size: "lg",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleChangeTimesheetsEfcSelected(
                                                                                    $event,
                                                                                    data.item
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  data
                                                                                    .item
                                                                                    .isSelected,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      data.item,
                                                                                      "isSelected",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "data.item.isSelected",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(validatedStatus)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wrap-status",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class: `status ${data.item.statusClass}`,
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "0.7rem",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .validatedStatus
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(encoder)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row align-items-center justify-content-center",
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.encodedBy(
                                                                            data.item
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        356858973
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          lazy: "",
                                          title: _vm.FormMSG(10002, "Pending"),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          { attrs: { "no-body": "" } },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "clearfix mb-4",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "fw-400",
                                                        staticStyle: {
                                                          "font-size": "13px",
                                                          color:
                                                            "rgba(6, 38, 62, 0.65)",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t*\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                12,
                                                                "Click to a timesheet to see the details. Timesheets are divided by week from Monday to Sunday."
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                !_vm.$screen.md
                                                  ? _c("CardListBuilder", {
                                                      attrs: {
                                                        items: _vm.Timesheets,
                                                        fields:
                                                          _vm.tsFieldsMobile,
                                                        "hide-status":
                                                          _vm.isFilmSingle,
                                                      },
                                                      on: {
                                                        "update:items":
                                                          function ($event) {
                                                            _vm.Timesheets =
                                                              $event
                                                          },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "actions",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.rowClicked(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "icon-eye",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        4217146928
                                                      ),
                                                    })
                                                  : _vm._e(),
                                                _vm.$screen.md
                                                  ? _c("b-table", {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                      attrs: {
                                                        hover: _vm.hover,
                                                        responsive: "sm",
                                                        items:
                                                          _vm.timeSheetsPending,
                                                        fields: _vm.tsFields,
                                                        "current-page":
                                                          _vm.currentPage,
                                                        "per-page": _vm.perPage,
                                                        "empty-text":
                                                          _vm.FormMSG(
                                                            56,
                                                            "No timesheet found"
                                                          ),
                                                        bordered: "",
                                                        small: "",
                                                        "sticky-header":
                                                          "700px",
                                                        "head-variant": _vm.hv,
                                                        "show-empty": "",
                                                      },
                                                      on: {
                                                        "row-clicked":
                                                          _vm.rowClicked,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "cell(validatedStatus)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wrap-status",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class: `status ${data.item.statusClass}`,
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "0.7rem",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .validatedStatus
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(encoder)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row align-items-center justify-content-center",
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.encodedBy(
                                                                            data.item
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1531514326
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          lazy: "",
                                          title: _vm.FormMSG(10003, "Approved"),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          { attrs: { "no-body": "" } },
                                          [
                                            _c(
                                              "b-card-body",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "clearfix mb-4",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "fw-400",
                                                        staticStyle: {
                                                          "font-size": "13px",
                                                          color:
                                                            "rgba(6, 38, 62, 0.65)",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t*\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                12,
                                                                "Click to a timesheet to see the details. Timesheets are divided by week from Monday to Sunday."
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                !_vm.$screen.md
                                                  ? _c("CardListBuilder", {
                                                      attrs: {
                                                        items: _vm.Timesheets,
                                                        fields:
                                                          _vm.tsFieldsMobile,
                                                        "hide-status":
                                                          _vm.isFilmSingle,
                                                      },
                                                      on: {
                                                        "update:items":
                                                          function ($event) {
                                                            _vm.Timesheets =
                                                              $event
                                                          },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "actions",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.rowClicked(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "icon-eye",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        4217146928
                                                      ),
                                                    })
                                                  : _vm._e(),
                                                _vm.$screen.md
                                                  ? _c("b-table", {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                      attrs: {
                                                        hover: _vm.hover,
                                                        responsive: "sm",
                                                        items:
                                                          _vm.timeSheetsApproved,
                                                        fields: _vm.tsFields,
                                                        "current-page":
                                                          _vm.currentPage,
                                                        "per-page": _vm.perPage,
                                                        "empty-text":
                                                          _vm.FormMSG(
                                                            56,
                                                            "No timesheet found"
                                                          ),
                                                        bordered: "",
                                                        small: "",
                                                        "sticky-header":
                                                          "700px",
                                                        "head-variant": _vm.hv,
                                                        "show-empty": "",
                                                      },
                                                      on: {
                                                        "row-clicked":
                                                          _vm.rowClicked,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "cell(validatedStatus)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wrap-status",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class: `status ${data.item.statusClass}`,
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "0.7rem",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .validatedStatus
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(encoder)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row align-items-center justify-content-center",
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.encodedBy(
                                                                            data.item
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1531514326
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }