<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
.header-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.label-txt {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div v-if="$screen.width <= 576" class="my-expense-mobile">
				<div class="title">
					<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
						{{ FormMSG(10, 'My timesheets') }}
					</h1>
				</div>
				<div class="my-expense-actions" :class="{ 'pt-16': $isPwa() }">
					<div class="d-flex justify-content-center wapper">
						<router-link class="btn-action text-center" to="/timesheet">
							<img src="svgs/timesheet.svg" alt="Purshase order" />
							<span class="px-2">{{ FormMSG(52020, 'Add Workday') }}</span>
						</router-link>
					</div>
				</div>
				<div class="d-flex flex-column w-100">
					<list-timesheet-mobile
						:time-sheets-approved="timeSheetsApproved"
						:time-sheets-pending="timeSheetsPending"
						:time-sheets-drafts="timeSheetsDrafts"
						:time-sheets="Timesheets"
						:can-see-approve="canSeeApprove"
						@submitted-all-ts="reloadData"
					/>
				</div>
			</div>
			<div v-else>
				<b-row v-if="Timesheets" class="animated fadeIn">
					<b-col cols="12" xl="12" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
						<div class="header-content">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(10, 'My timesheets') }}
							</h1>
							<router-link to="/timesheet" class="btn btn-success pull-right block-sm">
								{{ FormMSG(11, 'Add to timesheet') }}
							</router-link>
						</div>
						<b-card v-if="isFilmSingle" no-body>
							<b-card-body>
								<div class="clearfix mb-4">
									<p class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
										*
										{{ FormMSG(12, 'Click to a timesheet to see the details. Timesheets are divided by week from Monday to Sunday.') }}
									</p>
								</div>
								<CardListBuilder v-if="!$screen.md" :items.sync="Timesheets" :fields="tsFieldsMobile" :hide-status="isFilmSingle">
									<template slot="actions" slot-scope="data">
										<b-button variant="primary" @click="rowClicked(data.item)" size="sm">
											<i class="icon-eye"></i>
										</b-button>
									</template>
								</CardListBuilder>
								<b-table
									v-if="$screen.md"
									:hover="hover"
									responsive="sm"
									:items="Timesheets"
									style="text-align: center"
									:fields="tsFields"
									:current-page="currentPage"
									:per-page="perPage"
									:empty-text="FormMSG(56, 'No timesheet found')"
									bordered
									small
									sticky-header="700px"
									:head-variant="hv"
									show-empty
									@row-clicked="rowClicked"
								>
									<!-- <template slot="rem" slot-scope="data">-->
									<template #cell(validatedStatus)="data">
										<div class="wrap-status">
											<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
												{{ data.item.validatedStatus }}
											</div>
										</div>
									</template>
									<template #cell(encoder)="data">
										<div class="d-flex flex-row align-items-center justify-content-center">
											<div>{{ encodedBy(data.item) }}</div>
										</div>
									</template>
								</b-table>
							</b-card-body>
						</b-card>
						<b-tabs v-else v-model="currentExpenseTab">
							<b-tab v-if="canSeeApprove" :title="FormMSG(10000, 'Approve')">
								<ValidateTimesheets />
							</b-tab>
							<b-tab :active="activeDraft" lazy :title="FormMSG(10001, 'Drafts')">
								<b-card no-body>
									<b-card-body>
										<div class="mb-4 ml-2 w-100 d-flex" style="justify-content: space-between; align-items: center">
											<div v-if="timeSheetsDrafts.length > 0">
												<div v-if="itemIsNotSubmitted.length < timeSheetsDrafts.length">
													<b-form-checkbox
														size="lg"
														class="pj-cb"
														:value="true"
														v-model="allIsSelected"
														:unchecked-value="false"
														@change="selectAllNotSubmitted"
													>
														<div class="label-txt" style="margin-top: 1px">
															{{ this.FormMSG(10101011, 'Select all not submitted') }}
														</div>
													</b-form-checkbox>
												</div>
												<div v-else>
													<b-form-checkbox
														size="lg"
														class="pj-cb"
														v-model="allIsSelected"
														:value="true"
														:unchecked-value="false"
														@change="selectAllNotSubmitted"
													>
														<div class="label-txt" style="margin-top: 1px">
															{{ this.FormMSG(1010103, 'Unselect all not submitted') }}
														</div>
													</b-form-checkbox>
												</div>
											</div>
											<b-button :disabled="itemIsNotSubmitted.length == 0" @click="sendForValidation" class="btn-primary text-right">
												{{ FormMSG(2266523, 'Send for validation') }}
											</b-button>
										</div>
										<CardListBuilder v-if="!$screen.md" :items.sync="Timesheets" :fields="tsFieldsMobile" :hide-status="isFilmSingle">
											<template slot="actions" slot-scope="data">
												<b-button variant="primary" @click="rowClicked(data.item)" size="sm">
													<i class="icon-eye"></i>
												</b-button>
												<span class="ml-3 mt-1">
													<b-form-checkbox
														:id="generateSecureId(`timesheets-efc-selected-${data.item.id + Math.random()}`)"
														:name="generateSecureId(`timesheetsEfcSelected${data.item.id}`)"
														v-model="data.item.isSelected"
														size="lg"
														class="pj-cb pb-1"
														@change="handleChangeTimesheetsEfcSelected($event, data.item)"
													/>
												</span>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.md"
											:hover="hover"
											responsive="sm"
											class="w-100"
											:items="timeSheetsDrafts"
											style="text-align: center"
											:fields="tsFieldsWithSelection"
											:current-page="currentPage"
											:per-page="perPage"
											:empty-text="FormMSG(56, 'No timesheet found')"
											bordered
											small
											sticky-header="700px"
											:head-variant="hv"
											show-empty
											@row-clicked="rowClicked"
										>
											<template #cell(select)="data">
												<span>
													<b-form-checkbox
														:id="generateSecureId(`timesheets-efc-selected-${data.item.id + Math.random()}`)"
														:name="generateSecureId(`timesheetsEfcSelected${data.item.id}`)"
														v-model="data.item.isSelected"
														v-if="data.item.validated !== 4 && data.item.validated !== 16"
														size="lg"
														class="pj-cb pb-1"
														@change="handleChangeTimesheetsEfcSelected($event, data.item)"
													/>
												</span>
											</template>
											<!-- <template slot="rem" slot-scope="data">-->
											<template #cell(validatedStatus)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
											<template #cell(encoder)="data">
												<div class="d-flex flex-row align-items-center justify-content-center">
													<div>{{ encodedBy(data.item) }}</div>
												</div>
											</template>
										</b-table>
									</b-card-body>
								</b-card>
							</b-tab>
							<b-tab lazy :title="FormMSG(10002, 'Pending')">
								<b-card no-body>
									<b-card-body>
										<div class="clearfix mb-4">
											<p class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
												*
												{{
													FormMSG(
														12,
														'Click to a timesheet to see the details. Timesheets are divided by week from Monday to Sunday.'
													)
												}}
											</p>
										</div>

										<!--     TABLE OF Timesheets     sort-by="date"    use striped to highlight each even row  -->
										<CardListBuilder v-if="!$screen.md" :items.sync="Timesheets" :fields="tsFieldsMobile" :hide-status="isFilmSingle">
											<!--@row-clicked="rowClicked"-->
											<template slot="actions" slot-scope="data">
												<b-button variant="primary" @click="rowClicked(data.item)" size="sm">
													<i class="icon-eye" />
												</b-button>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.md"
											:hover="hover"
											responsive="sm"
											:items="timeSheetsPending"
											style="text-align: center"
											:fields="tsFields"
											:current-page="currentPage"
											:per-page="perPage"
											:empty-text="FormMSG(56, 'No timesheet found')"
											bordered
											small
											sticky-header="700px"
											:head-variant="hv"
											show-empty
											@row-clicked="rowClicked"
										>
											<!-- <template slot="rem" slot-scope="data">-->
											<template #cell(validatedStatus)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
											<template #cell(encoder)="data">
												<div class="d-flex flex-row align-items-center justify-content-center">
													<div>{{ encodedBy(data.item) }}</div>
												</div>
											</template>
										</b-table>
									</b-card-body>
								</b-card>
							</b-tab>
							<b-tab lazy :title="FormMSG(10003, 'Approved')">
								<b-card no-body>
									<b-card-body>
										<div class="clearfix mb-4">
											<p class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
												*
												{{
													FormMSG(
														12,
														'Click to a timesheet to see the details. Timesheets are divided by week from Monday to Sunday.'
													)
												}}
											</p>
										</div>
										<!--     TABLE OF Timesheets     sort-by="date"    use striped to highlight each even row  -->
										<CardListBuilder v-if="!$screen.md" :items.sync="Timesheets" :fields="tsFieldsMobile" :hide-status="isFilmSingle">
											<!--@row-clicked="rowClicked"-->
											<template slot="actions" slot-scope="data">
												<b-button variant="primary" @click="rowClicked(data.item)" size="sm">
													<i class="icon-eye" />
												</b-button>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.md"
											:hover="hover"
											responsive="sm"
											:items="timeSheetsApproved"
											style="text-align: center"
											:fields="tsFields"
											:current-page="currentPage"
											:per-page="perPage"
											:empty-text="FormMSG(56, 'No timesheet found')"
											bordered
											small
											sticky-header="700px"
											:head-variant="hv"
											show-empty
											@row-clicked="rowClicked"
										>
											<!-- <template slot="rem" slot-scope="data">-->
											<template #cell(validatedStatus)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
											<template #cell(encoder)="data">
												<div class="d-flex flex-row align-items-center justify-content-center">
													<div>{{ encodedBy(data.item) }}</div>
												</div>
											</template>
										</b-table>
									</b-card-body>
								</b-card>
							</b-tab>
						</b-tabs>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import { generateSecureId } from '@/shared/utils';
import { rendCurrency, rendKgCo2 } from '~helpers';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import { submitTimeSheetsSlice } from '@/cruds/timesheets-efc.crud';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import moment from 'moment';
import ListTimesheetMobile from '@/components/MyTimesheetMobile/ListTimesheetMobile';
import { AlertTriangle } from 'lucide-vue';
import { classStatus } from '@/shared/global-status';
import ValidateTimesheets from '../validateTS/ValidateTimesheets.vue';
import { getValidations } from '../../cruds/validations.crud';
import { isNil } from '../../shared/utils';

const query_timesheets = gql`
	query {
		myTimesheets {
			id
			strDate
			endDate
			week
			totMin
			validated
			comment
			salary
			totMinOvertime
			totMinNight
			totMinTooEarly
			kgCoTwo
			totMinOvertime1
			totMinOvertime2
			transportTimePaid
			hasNotSubmittedDay
			weeklyTotalOvertime
			weeklyTotalOvertimeStr
			weeklyTotMinOvt
			weeklyTotMinOvt1
			weeklyTotMinOvt2
			weeklySalaryOvt
			weeklySalaryOvt1
			weeklySalaryOvt2
			weeklyCarAllowance
			weeklyComputerAllowance
			weeklyProductionFeeAllowance
			weeklyPhoneAllowance
			weeklyBoxKitAllowance
			dailyTotAllowances
			weeklyTotAllowances
			weeklyTotPerDiem
			hours
			minutes
			hoursOvertime
			minutesOvertime
			hoursOvertime1
			hoursOvertime2
			minutesOvertime1
			minutesOvertime2
			hoursTransportTimePaid
			minutesTransportTimePaid
			hoursNight
			minutesNight
			hoursTooEarly
			minutesTooEarly
			department
			departmentName
			function
			functionName
			weeklyTotMinOvtStr
			weeklyTotMinOvt1Str
			weeklyTotMinOvt2Str
			weeklyLunchPerDiem
			weeklyHotelPerDiem
			weeklyDinnerPerDiem
			weeklyAbroadPerDiem
			user {
				name
				firstName
				email
			}
			encoderId
			encoder {
				id
				name
				fullName
				firstName
			}
		}
	}
`;
/**
 * @todo
 * Back Julis
 *
 * beforeCallTime
 * dailyBeforeCallTime
 * weeklyBeforeCallTime
 */

export default {
	name: 'MyTimesheets',

	components: {
		Loading,
		ListTimesheetMobile,
		AlertTriangle,
		ValidateTimesheets
	},
	mixins: [languageMessages, isSingleProjectMixin],
	props: {
		caption1: {
			type: String,
			default: 'My Timesheets'
		},
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			activeDraft: false,
			erreur: {},
			currentExpenseTab: 0,
			Timesheets: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			generateSecureId,
			isLoading: false,
			canSeeApprove: true,
			currentValidationLevel: 0,
			timeSheetsDrafts: [],
			itemsSelected: [],
			allIsSelected: false,
			itemIsNotSubmitted: [],
			timeSheetsPending: [],
			timeSheetsApproved: [],
			curTs: {
				id: 0,
				strDate: '',
				endDate: '',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				kgCoTwo: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			}
		};
	},
	watch: {
		timeSheetsDrafts: 'updateAllIsSelected',
		itemIsNotSubmitted: 'updateAllIsSelected',
		'$route.query.activeTab': {
			handler(newVal) {
				setTimeout(() => {
					if (newVal) {
						this.currentExpenseTab = +newVal;
					}
				}, 300);
			},
			immediate: true,
			deep: true
		},
		'$route.query.activeDraft': {
			handler(newVal) {
				if (newVal) {
					this.activeDraft = newVal;
				}
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		fliedsBase() {
			let fields = [];
			let baseFields = [
				{
					key: 'strDate',
					label: this.FormMSG(26, 'Start'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'endDate',
					label: this.FormMSG(27, 'End'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: false
				},
				{
					key: 'hhmm',
					label: this.FormMSG(21, 'Hours'),
					sortable: false
				}
			];

			fields = fields.concat(baseFields);

			const weeklyOvertime = {
				key: 'weeklyTotalOvertimeStr',
				label: this.FormMSG(111, 'Weekly Overtime'),
				sortable: false
			};

			if (this.showWeeklyOvt || this.showWeeklyOvt1 || this.showWeeklyOvt2) {
				fields.push(weeklyOvertime);
			}

			const dailyOvertime = {
				key: 'hhmmOvtTot',
				label: this.FormMSG(230, 'Daily Overtime'),
				sortable: false
			};

			if (this.showOvt || this.showOvt1 || this.showOvt2) {
				fields.push(dailyOvertime);
			}

			const transport = {
				key: 'hhmmTransport',
				label: this.FormMSG(231, 'Transport'),
				sortable: false
			};

			if (this.showTransport) {
				fields.push(transport);
			}

			const night = {
				key: 'hhmmNight',
				label: this.FormMSG(23, 'Night'),
				sortable: false
			};

			if (this.showNightHours) {
				fields.push(night);
			}

			const anticipated = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(24, 'Antic'),
				sortable: false
			};

			if (this.showTooEarlyHours) {
				fields.push(anticipated);
			}

			const beforeCallTime = {
				key: 'beforeCallTime',
				label: this.FormMSG(57, 'Precall'),
				sortable: false
			};

			if (this.showPreCall) {
				fields.push(beforeCallTime);
			}

			const perDiem = {
				key: 'weeklyTotPerDiem',
				label: this.FormMSG(233, 'Per Diem'),
				formatter: (value) => {
					return rendCurrency(value);
				},
				sortable: false,
				class: 'text-center'
			};

			if (this.showLunchPerDiem || this.showHotelPerDiem || this.showDinnerPerDiem || this.showAbroadPerDiem) {
				fields.push(perDiem);
			}

			let baseLastFields = [
				{
					key: 'weeklyTotAllowances',
					label: this.FormMSG(232, 'Allowances'),
					formatter: (v, z, root) => {
						return rendCurrency(root.dailyTotAllowances + root.weeklyTotAllowances);
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					class: 'text-right',
					sortable: true
				}
			];

			const salary = {
				key: 'salary',
				label: this.FormMSG(25000, 'Salary'),
				formatter: (value) => {
					return rendCurrency(value);
				},
				class: 'text-right',
				sortable: false
			};

			if (!store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget()) {
				baseLastFields.push(salary);
			}

			fields = fields.concat(baseLastFields);

			return fields;
		},
		tsFieldsWithSelection() {
			let flds = [];
			const statusFld = [
				{
					key: 'select',
					label: this.FormMSG(20150, 'Select'),
					sortable: false
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(20, 'Status'),
					sortable: true
				}
			];
			// console.log("isfilmsingle:",this.isFilmSingle)
			if (!this.isFilmSingle) {
				flds.push(...statusFld);
			}
			const fldsBase = this.fliedsBase;
			flds = flds.concat(fldsBase);
			const encodedByField = () => {
				if (!this.isFilmSingle) {
					return { key: 'encoder', label: this.FormMSG(58, 'Encoded By'), sortable: true };
				}
			};
			let arrays = [...flds.slice(0, 3), encodedByField(), ...flds.slice(3)];
			return arrays;
		},
		tsFields() {
			let flds = [];
			const statusFld = [
				{
					key: 'validatedStatus',
					label: this.FormMSG(20, 'Status'),
					sortable: true
				}
			];
			// console.log("isfilmsingle:",this.isFilmSingle)
			if (!this.isFilmSingle) {
				flds.push(...statusFld);
			}
			const fldsBase = this.fliedsBase;
			flds = flds.concat(fldsBase);
			const encodedByField = () => {
				if (!this.isFilmSingle) {
					return { key: 'encoder', label: this.FormMSG(58, 'Encoded By'), sortable: true };
				}
			};
			let arrays = [...flds.slice(0, 3), encodedByField(), ...flds.slice(3)];
			return arrays;
		},
		styleObject: function () {
			let myObj = {
				color: 'white',
				fontSize: '14px'
			};
			if (this.curTs.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curTs.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curTs.validated == 8) {
				myObj.color = 'green';
			} else if (this.curTs.validated == 4 || this.curTs.validated == 16) {
				myObj.color = 'red';
			} else if (this.curTs.validated == 0) {
				myObj.color = 'red';
			}

			return myObj;
		},
		tsFieldsMobile() {
			return this.fliedsBase;
		},
		showOvt() {
			return store.state.myProject.useOvertime;
		},
		showOvt1() {
			return store.state.myProject.useOvt1;
		},
		showOvt2() {
			return store.state.myProject.useOvt2;
		},
		showWeeklyOvt() {
			return store.state.myProject.useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.state.myProject.useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.state.myProject.useWeeklyOvt2;
		},
		showTransport() {
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		showTooEarlyHours() {
			return store.state.myProject.useTooEarlyHours;
		},
		showNightHours() {
			return store.state.myProject.useNightHours;
		},
		showPreCall() {
			return store.state.myProject.useBeforeCallTime;
		}
	},
	methods: {
		updateAllIsSelected() {
			this.allIsSelected = this.timeSheetsDrafts.length === this.itemIsNotSubmitted.length;
		},
		async sendForValidation() {
			for (let i = 0; i < this.itemIsNotSubmitted.length; i++) {
				let tsDaysToSend = +this.itemIsNotSubmitted[i].id;
				await submitTimeSheetsSlice(tsDaysToSend).then((res) => {
					if (res) {
						this.itemIsNotSubmitted.filter((item) => item.id !== tsDaysToSend);
					}
				});
			}
			if (this.itemIsNotSubmitted.length === 0) {
				let strTitle = this.FormMSG(14509, 'Success');
				this.$bvToast.toast(this.FormMSG(124101, 'All not submitted for validation send'), {
					title: strTitle,
					variant: 'success',
					toaster: 'b-toaster-top-center',
					solid: true
				});
			}
			this.reloadData();
		},
		handleChangeTimesheetsEfcSelected($event, data) {
			if ($event) {
				this.itemIsNotSubmitted = [...this.itemIsNotSubmitted, data];
			} else {
				this.itemIsNotSubmitted = this.itemIsNotSubmitted.filter((item) => item.id !== data.id);
			}
			return this.itemIsNotSubmitted;
		},
		selectAllNotSubmitted(e) {
			this.allIsSelected = e;
			let result = this.timeSheetsDrafts.map((item) => {
				if (this.allIsSelected) {
					return { ...item, isSelected: true };
				}
				return { ...item, isSelected: false };
			});
			this.timeSheetsDrafts = result;
			if (e) {
				this.itemIsNotSubmitted = this.timeSheetsDrafts.filter((item) => item.validated !== 4 || item.validated !== 16);
			} else {
				this.itemIsNotSubmitted = [];
			}
		},
		async getRoleValidation() {
			const validations = await getValidations();
			const userId = store.userID();
			const departmentId = store.state.myProfile.department;

			let haveValidationAllDepartment = null;
			let haveValidationSingleDepartment = null;

			for (let i = 0; i < validations.length; i++) {
				const element = validations[i];
				if (parseInt(element.department, 10) === -1 && parseInt(element.user.id, 10) === userId && element.type == 0) {
					haveValidationAllDepartment = element;
				} else if (parseInt(element.user.id, 10) === userId && parseInt(element.department, 10) === departmentId && element.type == 0) {
					haveValidationSingleDepartment = element;
				}
			}
			// console.log(haveValidationAllDepartment);
			if (!isNil(haveValidationSingleDepartment) && !isNil(haveValidationAllDepartment)) {
				if (haveValidationSingleDepartment.validationLevelTs > haveValidationAllDepartment.validationLevelTs) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelTs;
				} else {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelTs;
				}
			} else {
				if (!isNil(haveValidationSingleDepartment)) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelTs;
				} else if (!isNil(haveValidationAllDepartment)) {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelTs;
				}
			}
			if (this.currentValidationLevel == 2 || this.currentValidationLevel == 1) {
				this.canSeeApprove = true;
			} else {
				this.canSeeApprove = false;
			}
			// alert(this.canSeeApprove);
		},
		encodedBy(data) {
			let name = data.encoder !== undefined ? data.encoder.name : '';
			let firstName = data.encoder !== undefined ? data.encoder.firstName : '';
			return `${name} ${firstName}`;
		},
		selectCurTs() {
			// check if curTs is in array of existing timesheet.
			// if yes, select it, if not, initialise a new curTs with defaultvalues
			// look if chosen date is in timesheet, if yes get structure
			let foundCurTsInArray = false;
			//console.log("in selectCurTs");
			for (let i = 0; i < this.Timesheets.length; i++) {
				let temp = this.Timesheets[i].strDate;
				temp = temp.substring(0, 10);
				// console.log('temp:', temp);
				if (temp == this.curDay.strDate) {
					// fill curDay with tsTays Data
					this.curTs = this.Timesheets[i];
					//    console.log("in selectTs found line:", this.curTs.strDate)
					foundCurTsInArray = true;
					break;
				}
			}
			if (foundCurTsInArray == false) {
				if (this.Timesheets.length > 0) {
					this.curTs = this.Timesheets[0];
				} else {
					console.log('in selectCurTs not found in array');
					this.curTs.strDate = '2019-01-01';
				}
			}
		},
		getCurrentDate() {
			const currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {},
		updateTZdataEach(timeData) {
			// setting row color
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			// timeData._rowVariant = ''; //this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			timeData.statusClass = classStatus(timeData.validated);
			//hh:mm
			timeData.isSelected = false;
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;
			if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) >= 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) >= 10
			) {
				timeData.hhmmOvtTot =
					parseInt(timeData.hoursOvertime, 10) +
					parseInt(timeData.hoursOvertime1, 10) +
					parseInt(timeData.hoursOvertime2, 10) +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10));
			} else if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) >= 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) < 10
			) {
				timeData.hhmmOvtTot =
					(parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10))
						.toString()
						.padStart(2, '0') +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10));
			} else if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) < 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) >= 10
			) {
				timeData.hhmmOvtTot =
					parseInt(timeData.hoursOvertime, 10) +
					parseInt(timeData.hoursOvertime1, 10) +
					parseInt(timeData.hoursOvertime2, 10) +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10))
						.toString()
						.padStart(2, '0');
			} else if (
				parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10) < 10 &&
				parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10) < 10
			) {
				timeData.hhmmOvtTot =
					(parseInt(timeData.hoursOvertime, 10) + parseInt(timeData.hoursOvertime1, 10) + parseInt(timeData.hoursOvertime2, 10))
						.toString()
						.padStart(2, '0') +
					':' +
					(parseInt(timeData.minutesOvertime, 10) + parseInt(timeData.minutesOvertime1, 10) + parseInt(timeData.minutesOvertime2, 10))
						.toString()
						.padStart(2, '0');
			}
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			let retval = '';

			if (validated == 1) {
				retval = 'primary';
			} else if (validated == 2) {
				retval = 'warning';
			} else if (validated == 8) {
				retval = 'success';
			} else if (validated == 4 || validated == 16) {
				retval = 'danger';
			} else {
				retval = 'info';
			}

			return retval;
		},
		reloadData() {
			this.getRoleValidation();
			this.isLoading = true;
			this.itemIsNotSubmitted = [];
			this.Timesheets = [];
			this.timeSheetsDrafts = [];
			this.timeSheetsPending = [];
			this.timeSheetsApproved = [];
			// alert('here');

			this.$apollo
				.query({
					query: query_timesheets,
					variables: {},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then(async (result) => {
					result.data.myTimesheets.forEach(this.updateTZdataEach);
					this.Timesheets = result.data.myTimesheets;
					// console.log(this.Timesheets);
					this.timeSheetsDrafts = result.data.myTimesheets.filter((res) => res.validated == 0 || res.validated == 4 || res.validated == 16);
					this.timeSheetsPending = result.data.myTimesheets.filter((res) => res.validated == 1 || res.validated == 2);
					this.timeSheetsApproved = result.data.myTimesheets.filter((res) => res.validated == 8);
					//_.orderBy(Timesheets, ['strDate'], ['desc']);
					// console.log("reloaddata:", this.Timesheets);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				})
				.finally(() => (this.isLoading = false));
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		tsDetailLink(id) {
			return `/mytimesheets/${id.toString()}`;
		},
		rowClicked(item) {
			// copy item content into curTs structure.
			store.setCurTimesheet(item);
			//  console.log("item :", item);
			const tsLink = this.tsDetailLink(item.id);
			//      console.log("rowclicked starting:",tsLink);
			this.$router.push({
				path: tsLink,
				query: {
					previousActiveTab: this.currentExpenseTab
				}
			});
		}
	},
	created() {
		if (this.curTs.strDate.length == 0) {
			this.firstTimeInitialisation();
		}

		this.reloadData();
	}
};
</script>
